import { Form } from '~/components/form/Form'
import { useForm } from '~/hooks/useForm'
import { CreateOrganizationSchema } from './route.config'

type CreateOrgFormProps =
  | {
      successPath: string
      onSuccess?: never
    }
  | {
      successPath?: never
      onSuccess: () => void
    }

export const CreateOrgForm = ({
  successPath,
  onSuccess,
}: CreateOrgFormProps) => {
  const [form, fields] = useForm({
    schema: CreateOrganizationSchema,
    useFetcher: !successPath,
    defaultValue: { successPath },
    shouldValidate: 'onSubmit',
    onSuccess,
  })

  return (
    <Form form={form} action="/api/organizations">
      <Form.HiddenInput name={fields.successPath.name} />
      <div className="space-y-4">
        <Form.Field name={fields.name.name}>
          <Form.Label>Name</Form.Label>
          <Form.Input icon="building" placeholder="e.g. Pied Piper" />
          <Form.FieldErrors />
        </Form.Field>

        <Form.SubmitButton className="w-full">Create</Form.SubmitButton>
      </div>
    </Form>
  )
}
